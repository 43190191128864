import React from "react";
import { BsLinkedin, BsGithub, BsTwitter } from "react-icons/bs";

const SocialMedia = () => {
	return (
		<div className="app__social">
			<a
				href="https://www.linkedin.com/in/max-remy-9525a9262/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<BsLinkedin />
			</a>
			<a
				href="https://www.github.com/babyloopro"
				target="_blank"
				rel="noopener noreferrer"
			>
				<BsGithub />
			</a>
			<a
				href="https://www.twitter.com/babyloopro"
				target="_blank"
				rel="noopener noreferrer"
			>
				<BsTwitter />
			</a>
		</div>
	);
};

export default SocialMedia;

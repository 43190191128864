import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

const Footer = () => {
	const [copied, setCopied] = useState(false);
	const [emailCopied, setEmailCopied] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitError, setSubmitError] = useState("");
	const [formData, setFormData] = useState({
		username: "",
		email: "",
		message: "",
	});
	const { username, email, message } = formData;

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const [formErrors, setFormErrors] = useState({
		username: false,
		email: false,
		message: false,
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitError("");

		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		const newErrors = {
			username: !username.trim(),
			email: !emailRegex.test(email.trim()),
			message: !message.trim(),
		};

		setFormErrors(newErrors);

		if (newErrors.username || newErrors.message) {
			setLoading(false);
			setSubmitError("Please complete all fields");
			return;
		} else if (newErrors.email) {
			setLoading(false);
			setSubmitError("Please provide a valid email address");
			return;
		}

		setLoading(true);

		const endpoint =
			"https://amzw4k76o4.execute-api.us-east-1.amazonaws.com/prod/send-email";

		fetch(endpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to send message");
				}
				return response.json();
			})
			.then((data) => {
				setIsFormSubmitted(true);
			})
			.catch((error) => {
				setSubmitError(error.message);
			})
			.finally(() => {
				setLoading(false);
			});

		client
			.create({
				_type: "contact",
				name: formData.username,
				email: formData.email,
				message: formData.message,
			})
			.catch((error) => {
				console.error("Error sending message to sanity.io:", error);
			});
	};

	return (
		<>
			<h2 className="head-text">Contact Me</h2>

			<div className="app__footer-cards">
				<CopyToClipboard
					text={"maxremy.dev@gmail.com"}
					onCopy={() => {
						setEmailCopied(true);
						setTimeout(() => setEmailCopied(false), 5000);
					}}
				>
					<div className="app__footer-card">
						<img src={images.email} alt="email" />
						<div className="p-text">maxremy.dev@gmail.com</div>
						{emailCopied ? (
							<span
								className="bold-text"
								style={{ color: "red", marginLeft: "10px" }}
							>
								Copy
							</span>
						) : null}
					</div>
				</CopyToClipboard>
				<CopyToClipboard
					text={"babyloopro"}
					onCopy={() => {
						setCopied(true);
						setTimeout(() => setCopied(false), 5000);
					}}
				>
					<div className="app__footer-card">
						<img
							className="card-image"
							src={images.discord}
							alt="discord"
						/>
						<div className="p-text">babyloopro</div>
						{copied ? (
							<span
								className="bold-text"
								style={{ color: "red", marginLeft: "10px" }}
							>
								Copy
							</span>
						) : null}
					</div>
				</CopyToClipboard>
			</div>
			{!isFormSubmitted ? (
				<div className="app__footer-form app__flex">
					<div className="app__flex">
						<input
							className={`p-text ${
								formErrors.username
									? "app__footer-form_textarea_error"
									: ""
							}`}
							type="text"
							placeholder="Your Name"
							name="username"
							value={username}
							onChange={handleChangeInput}
						/>
					</div>
					<div className="app__flex">
						<input
							className={`p-text ${
								formErrors.email
									? "app__footer-form_textarea_error"
									: ""
							}`}
							type="email"
							placeholder="Your Email"
							name="email"
							value={email}
							onChange={handleChangeInput}
						/>
					</div>
					<div>
						<textarea
							className={`p-text ${
								formErrors.message
									? "app__footer-form_textarea_error"
									: ""
							}`}
							placeholder="Your Message"
							value={message}
							name="message"
							onChange={handleChangeInput}
						/>
					</div>
					<button
						type="button"
						className="p-text"
						onClick={handleSubmit}
					>
						{!loading
							? submitError
								? submitError
								: "Send Message"
							: "Sending..."}
					</button>
				</div>
			) : (
				<div>
					<h3 className="head-text">
						Thank you for your message ! <br /> I will answer you as
						soon as possible.
					</h3>
				</div>
			)}
		</>
	);
};

export default AppWrap(
	MotionWrap(Footer, "app__footer"),
	"contact",
	"app__whitebg"
);

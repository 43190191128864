import React, { useState } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import "./Navbar.scss";

const itemVariants = {
	open: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.5 },
	},
	closed: { opacity: 0, y: 20, transition: { duration: 0.5 } },
};

const BurgerVariants = {
	open: { d: "M 5 5 L 30 5 M 5 15 L 30 15 M 5 25 L 30 25" },
	closed: { d: "M 5 5 L 30 25 M 5 25 L 30 5" },
};

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	// const [menuIsActive, setMenuIsActive] = useState(false);

	// const toggleMenu = () => {
	// 	setMenuIsActive(!menuIsActive);
	// };

	return (
		<div className="page-container">
			<nav className="app__navbar">
				{/*LOGO ANIMATION*/}
				<div className="app__navbar-logo">
					<a href="#home">
						<img src={images.logo} alt="logo" />
					</a>
				</div>

				{/*NAVBAR*/}
				<ul className="app__navbar-links">
					{["home", "skills", "testimonial", "contact"].map(
						(item) => (
							<li
								className="app__flex p-text"
								key={`link-${item}`}
							>
								<div />
								<a href={`#${item}`}>{item}</a>
							</li>
						)
					)}
				</ul>

				{/*BUTTON HAMBURGER*/}
				<motion.button
					whileTap={{ scale: 0.9 }}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
					className="app__navbar-burger"
				>
					<svg width="35" height="30" viewBox="0 0 35 30">
						<motion.path
							fill="transparent"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
							stroke="currentColor"
							variants={BurgerVariants}
							initial={false}
							animate={isOpen ? "closed" : "open"}
						/>
					</svg>
				</motion.button>

				{/* NAVBAR ACTIVE RESPONSIVE*/}
				<motion.nav
					initial={false}
					animate={isOpen ? "open" : "closed"}
					className={`app__navbar-menu ${isOpen ? "active" : ""}`}
				>
					{/*SHOW MENU*/}
					<div
						style={{
							overflow: "hidden",
							borderRadius: "25px",

							boxShadow: isOpen
								? "0px 0px 25px rgba(0,0,0,0.25)"
								: "none",
							transform: isOpen
								? "translateY(0)"
								: "translateY(-100%)",
							transition: "all 0.3s",
						}}
					>
						<motion.div>
							<motion.ul
								style={{
									pointerEvents: isOpen ? "auto" : "none",
									boxShadow: isOpen
										? "0px 0px 25px rgba(0,0,0,0.25)"
										: "none",
								}}
								variants={{
									open: {
										clipPath:
											"inset(0% 0% 0% 0% round 25px)",
										transition: {
											type: "spring",
											bounce: 0,
											duration: 0.7,
											delayChildren: 0.3,
											staggerChildren: 0.05,
										},
									},
									closed: {
										clipPath:
											"inset(0% 50% 100% 50% round 25px)",
										transition: {
											type: "spring",
											bounce: 0,
											duration: 0.3,
										},
									},
								}}
							>
								{[
									"home",
									// "work",
									"skills",
									//"pricing",
									"testimonial",
									"contact",
								].map((item) => (
									<motion.li
										key={item}
										variants={itemVariants}
									>
										<a
											href={`#${item}`}
											onClick={() => setIsOpen(false)}
										>
											{item}
										</a>
									</motion.li>
								))}
							</motion.ul>
						</motion.div>
					</div>
				</motion.nav>
			</nav>
		</div>
	);
};

export default Navbar;

import React from "react";
import { client } from "../../client";
import { DialogTitle, DialogContent, Typography } from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

async function fetchWarningCardTabsFromSanity() {
	const query = "*[_type == 'warningCardTabs'] | order(order asc)";
	const data = await client.fetch(query);
	return data;
}

const serializers = {
	types: {
		block(props) {
			switch (props.node.style) {
				case "h1":
					return (
						<Typography
							className="WarningCard_DialogText head-text"
							align="center"
						>
							{props.children}
						</Typography>
					);
				case "normal":
					return (
						<Typography
							className="WarningCard_DialogContent-Text p-text"
							align="center"
						>
							{props.children}
						</Typography>
					);
				default:
					return (
						<Typography align="center">{props.children}</Typography>
					);
			}
		},
	},

	marks: {
		color: (props) => {
			const color = props.mark.Color.rgb;
			const colorString = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
			return <span style={{ color: colorString }}>{props.children}</span>;
		},
		align: (props) => (
			<span style={{ display: "block", textAlign: props.mark.align }}>
				{props.children}
			</span>
		),
	},
};

async function getWarningCardTabs() {
	const sanityData = await fetchWarningCardTabsFromSanity();

	const allWarningCardTabs = sanityData
		.map((item) => {
			return {
				icon: item.icon,
				name: item.name,
				label: (
					<DialogContent className="WarningCard_DialogContent">
						{/* Red Head Text Title Label */}
						<DialogTitle className="WarningCard_DialogTitle">
							<Typography
								className="WarningCard_DialogText head-text"
								align="center"
							>
								{item.titleLabelHeadText}
							</Typography>
						</DialogTitle>
						{/* Normal Title Label */}
						<Typography
							className="WarningCard_DialogContent-Bold bold-text"
							align="left"
						>
							{item.titleLabel}
						</Typography>
						{/* Text Content Bold Text */}
						<Typography
							className="WarningCard_DialogContent-Bold bold-text"
							align="center"
						>
							{item.textContentBoldText}
						</Typography>
						{/* Normal Text Content */}
						<BlockContent
							className="WarningCard_DialogContent-Text p-text"
							blocks={item.textContent}
							serializers={serializers}
						/>
					</DialogContent>
				),
			};
		})
		.filter(Boolean);

	return allWarningCardTabs;
}

export { getWarningCardTabs };

import csharp from "../assets/csharp.png";
import python from "../assets/python.png";
import react from "../assets/react.png";

import logo from "../assets/logo.png";
import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import hand from "../assets/hand.png";

import email from "../assets/email.png";
import discord from "../assets/discord.png";

import constructionSign from "../assets/construction-sign.png";

const images = {
	csharp,
	python,
	react,
	logo,
	profile,
	circle,
	hand,
	email,
	discord,
	constructionSign,
};

export default images;

import React, { useState, useEffect, useLayoutEffect } from "react";
import { motion, useSpring } from "framer-motion";
import { Tooltip } from "react-tooltip";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";

const Skills = () => {
	const [experience, setExperience] = useState([]);

	const scaleY = useSpring({
		stiffness: 100,
		damping: 30,
		restDelta: 0.001,
	});

	useEffect(() => {
		const query = "*[_type == 'experiences'] | order(order asc)";
		client.fetch(query).then((data) => {
			setExperience(data);
		});
	}, []);

	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const totalHeight = document.body.scrollHeight - window.innerHeight;
			const scrollPosition = window.scrollY;
			const scrollPercentage = scrollPosition / totalHeight;
			setScrollPosition(scrollPercentage);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useLayoutEffect(() => {
		const items = document.querySelectorAll(".app__skills-exp-item");
		items.forEach((item, index) => {
			if (index % 2 !== 0) {
				const children = Array.from(item.children);
				// children.reverse(); //! no working
				item.innerHTML = "";
				children.forEach((child) => {
					item.appendChild(child);
				});
			}
		});
	}, [experience]);

	return (
		<>
			<h2 className="head-text">Skills & Experience</h2>
			<div className="app__skills-container">
				<motion.div className="app__skills-exp">
					<div className="app__skills-progress-bar-container">
						<motion.div
							className="app__skills-progress-bar"
							whileInView={{ opacity: [0, 1] }}
							transition={{ duration: 2 }}
							style={{
								scaleY,
								height: `${scrollPosition * 25}%`, //TODO: fix this to be synced with the scroll position with center axe
							}}
						/>
					</div>
					{experience.map((experience, index) => (
						<motion.div
							className={`app__skills-exp-item ${
								index % 2 !== 0
									? "app__skills-exp-item--reverse"
									: ""
							}`}
							key={`${experience.year}-${index}`}
						>
							<motion.div
								className="app__skills-exp-year"
								whileInView={{ opacity: [0, 1] }}
								transition={{ duration: 2 }}
							>
								<p className="bold-text">{experience.year}</p>
							</motion.div>
							<motion.div className="app__skills-exp-works">
								{experience.works.map((work, workIndex) => (
									<div key={`${work.name}-${workIndex}`}>
										<motion.div
											whileInView={{ opacity: [0, 1] }}
											transition={{ duration: 2 }}
											className="app__skills-exp-work"
											data-tooltip-id={`${work.name}-${index}`}
											key={`${work.name}-${workIndex}`}
										>
											<div className="app__flex">
												<img
													src={urlFor(work.icon)}
													alt={work.name}
												/>
											</div>
											<h4 className="bold-text">
												{work.name}
											</h4>
										</motion.div>
										<Tooltip
											id={`${work.name}-${index}`}
											effect="solid"
											arrowColor="#fff"
											place="left"
											// place={
											// 	index % 2 !== 0
											// 		? "left"
											// 		: "right"
											// }
											className="skills-tooltip"
										>
											<div className="desc-text p-text">
												{work.desc}
											</div>
											<div className="skills-tooltip-progress">
												<div className="skills-tooltip-progress-name">
													{work.name}
												</div>
												<div className="skills-tooltip-progress-level">
													<div
														className="skills-tooltip-progress-percent"
														style={{
															width: work.percent,
														}}
													/>
												</div>
												<div className="skills-tooltip-progress-percent-number">
													{work.percent}
												</div>
											</div>
										</Tooltip>
									</div>
								))}
							</motion.div>
						</motion.div>
					))}
				</motion.div>
				<motion.div
					className="currently-text bold-text app__flex"
					whileInView={{ opacity: [0, 1] }}
					transition={{ duration: 2 }}
				>
					CURRENTLY
				</motion.div>
			</div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Skills, "app__skills"),
	"skills",
	"app__whitebg"
);

import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
	projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
	dataset: "production",
	apiVersion: "2023-03-25",
	useCdn: true,
	token: process.env.REACT_APP_SANITY_TOKEN,
	ignoreBrowserTokenWarning: true,
});

const builder = imageUrlBuilder(client);

//! TRYING TO FETCH DATA FROM SANITY TO CONSOLE LOG IT
//client
//	.fetch('*[_type == "document"][0..9]')
//	.then((documents) => {
//		console.log(documents);
//	})
//	.catch((err) => {
//		console.error("Error !", err);
//	});

export const urlFor = (source) => builder.image(source);

import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, DialogContent } from "@mui/material";
import { styled } from "@mui/system";
import { getWarningCardTabs } from "./WarningCardTabs";
import { urlFor } from "../../client";
import "./WarningCard.scss";

const BlurryDialog = styled(Dialog)(({ theme }) => ({
	backdropFilter: "blur(5px)",
	position: "fixed",
	width: "100%",
	height: "100%",
}));

const WarningCard = () => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef(null);
	const [WarningCardTabs, setWarningCardTabs] = useState([]);
	const [selectedTab, setSelectedTab] = useState({
		textContent: [],
	});

	useEffect(() => {
		getWarningCardTabs().then((data) => {
			setWarningCardTabs(data);
			setSelectedTab(data[0]);
		});
	}, []);

	// Define your animation states
	const animationStates = {
		hidden: { y: 10, opacity: 0 },
		visible: { y: 0, opacity: 1 },
		exit: { y: -10, opacity: 0 },
	};

	// use variant instead of Animation Controls
	const [variant, setVariant] = useState("hidden");

	useEffect(() => {
		if (selectedTab) {
			setVariant("visible");
		} else {
			setVariant("hidden");
		}
	}, [selectedTab]);
	useEffect(() => {
		setOpen(true);

		const handleMouseMove = (e) => {
			if (dialogRef.current) {
				const rect = dialogRef.current.getBoundingClientRect();
				const isInDialog =
					e.clientX >= rect.left &&
					e.clientX <= rect.right &&
					e.clientY >= rect.top &&
					e.clientY <= rect.bottom;

				document.body.style.cursor = isInDialog ? "default" : "pointer";
			}
		};

		document.addEventListener("mousemove", handleMouseMove);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRedBoxClick = () => {
		setOpen(false);
	};

	return (
		<BlurryDialog
			open={open}
			onClose={handleClose}
			className="Dialog"
			PaperProps={{
				sx: {
					width: "800px",
					height: {
						xs: "400px", //! OLD = 500px
						md: "400px",
					},
					borderRadius: "10px",
					cursor: "default",
					boxShadow: "0px 0px 100px rgba(0,0,0,1)",
				},
			}}
			ref={dialogRef}
		>
			{/* CLOSE MINIMIZE FULLSCREEN SELECTION */}
			<div className="WarningCard_tools">
				<div className="WarningCard_circle">
					<span
						className="WarningCard_box red"
						onClick={handleRedBoxClick}
					></span>
				</div>
				<div className="WarningCard_circle">
					<span className="WarningCard_box yellow"></span>
				</div>
				<div className="WarningCard_circle">
					<span className="WarningCard_box green"></span>
				</div>
			</div>
			{/* TAB SELECTION */}
			<div className="WarningCard_nav-tab">
				<ul>
					{WarningCardTabs.map((item) => (
						<li
							key={item.name}
							className={
								item === selectedTab
									? "WarningCard_tab_selected"
									: ""
							}
							onClick={() => setSelectedTab(item)}
						>
							<img
								className="WarningCard_icon"
								src={urlFor(item.icon)}
								alt={item.name}
							/>
							<div className="WarningCard_item-name">
								{item.name}
							</div>

							{item === selectedTab ? (
								<motion.div
									className="WarningCard_underline"
									layoutId="WarningCard_underline"
								/>
							) : null}
						</li>
					))}
				</ul>
			</div>
			<DialogContent
				className="WarningCard_DialogContent"
				style={{ paddingTop: "0" }}
			>
				<AnimatePresence>
					{selectedTab ? (
						<motion.div
							key={selectedTab._id}
							variants={animationStates}
							initial="hidden"
							animate={variant}
							exit="exit"
							transition={{ duration: 1 }}
						>
							{selectedTab.label || "Waiting for data..."}
						</motion.div>
					) : null}
				</AnimatePresence>
			</DialogContent>
		</BlurryDialog>
	);
};

export default WarningCard;

import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { Grid, Typography, CardContent, CssBaseline } from "@mui/material";
import "./Pricing.scss";

const MotionGrid = motion(Grid, { forwardMotionProps: true });

const cards = [
	{
		price: "300€",
		period: "/d",
		title: "Without Design Package",
		info: "Frontend & Backend Development",
		tags: ["Web", "Mobile"],
		features: [
			{
				icon: "done",
				text: (
					<>
						<strong>Frontend</strong> Development
					</>
				),
			},
			{
				icon: "done",
				text: (
					<>
						<strong>Backend</strong> Development
					</>
				),
			},
			{ icon: "done", text: "Testing, Debug" },
			{ icon: "min", text: "Deployment" },
			{ icon: "none", text: "Design Mockup" },
		],
		details: [
			{ label: "Handmade", value: "~50%" },
			{ label: "Daytime", value: "~15" },
		],
	},
	{
		price: "400€",
		period: "/d",
		title: "Basic Package",
		info: "Design Mockup, Frontend & Backend",
		tags: ["Web", "Mobile", "Figma"],
		features: [
			{ icon: "done", text: "One Design Mockup" },
			{
				icon: "done",
				text: (
					<>
						<strong>Backend</strong> Development
					</>
				),
			},
			{
				icon: "done",
				text: (
					<>
						<strong>Frontend</strong> Development
					</>
				),
			},
			{ icon: "min", text: "Testing, Debug & Deployment" },
			{ icon: "none", text: "Contract Technical Support" },
		],
		details: [
			{ label: "Handmade", value: "~90%" },
			{ label: "Daytime", value: "~30" },
		],
	},
	{
		price: "600€",
		period: "/d",
		title: "Premium Package",
		info: "2 Design Mockup, Fullstack & Support",
		tags: ["Software", "Game", "Web", "Mobile"],
		features: [
			{
				icon: "done",
				text: (
					<>
						Two <strong>Premium</strong> Design Mockup
					</>
				),
			},
			{
				icon: "done",
				text: (
					<>
						<strong>Frontend & Backend</strong> Development
					</>
				),
			},
			{ icon: "done", text: "Testing, Debug, Deployment & Support" },
			{ icon: "min", text: "Hosting Service" },
			{ icon: "none", text: "Training Team To Manage" },
		],
		details: [
			{ label: "Handmade", value: "100%" },
			{ label: "Daytime", value: "+100" },
		],
	},
	{
		price: "800€",
		period: "/d",
		title: "Ultimate Package",
		info: "5 Design mockup, Fullstack & Other Services",
		tags: ["ALL SERVICES"],
		features: [
			{
				icon: "done",
				text: (
					<>
						<strong>Ultimate</strong> Design FullStack Development
					</>
				),
			},
			{ icon: "done", text: "Testing, Debug, Deployment & Support" },
			{ icon: "done", text: "Hosting Service" },
			{ icon: "done", text: "Training Team To Manage" },
			{ icon: "done", text: "Enhanced Security" },
		],
		details: [
			{ label: "Contract", value: "BIG" },
			{ label: "Handmade", value: "100%" },
			{ label: "Daytime", value: "+200" },
		],
	},
	{
		price: "0€",
		period: "/d",
		title: "Custom Package",
		info: "Custom",
		tags: ["Custom"],
		features: [
			{
				icon: "done",
				text: (
					<>
						<strong>Custom 1</strong>
					</>
				),
			},
			{
				icon: "min",
				text: (
					<>
						<strong>Custom 2</strong>
					</>
				),
			},
			{
				icon: "done",
				text: (
					<>
						<strong>Custom 3</strong>
					</>
				),
			},
			{
				icon: "none",
				text: (
					<>
						<strong>Custom 4</strong>
					</>
				),
			},
			{
				icon: "done",
				text: (
					<>
						<strong>Custom 5</strong>
					</>
				),
			},
		],
		details: [
			{ label: "Custom", value: "0%" },
			{ label: "Custom", value: "0" },
		],
	},
];

const getIcon = (iconName) => {
	switch (iconName) {
		case "done":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					width="24"
					height="24"
				>
					<path fill="none" d="M0 0h24v24H0z" />
					<path
						d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
						fill="currentColor"
					/>
				</svg>
			);
		case "min":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					width="24"
					height="24"
				>
					<path d="M2 12h20" stroke="currentColor" strokeWidth="2" />
				</svg>
			);
		case "none":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					width="24"
					height="24"
				>
					<path fill="none" d="M0 0h24v24H0z" />
					<path
						d="M6 6L18 18M6 18L18 6"
						stroke="currentColor"
						strokeWidth="2"
					/>
				</svg>
			);
		default:
			return null;
	}
};

const cardsContainerVariants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			delayChildren: 0.7,
			staggerChildren: 0.5,
		},
	},
};

const cardItemVariants = {
	hidden: { opacity: 0, scale: 0.5 },
	show: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 1.4,
			type: "spring",
			damping: 5,
			stiffness: 70,
		},
	},
};

const Pricing = () => {
	return (
		<>
			<CssBaseline />
			<Grid
				container
				flexDirection="column"
				alignItems="center"
				height="100%" //!old 100vh
			>
				<h2 className="head-text">Offer Package Pricing</h2>
				<h2 className="head-text">(Work in Progress)</h2>
				<div
					className="p-text"
					style={{
						textAlign: "center",
						marginTop: "1rem",
						width: "375px",
						lineHeight: "1.5",
						wordWrap: "break-word",
					}}
				>
					Please note that this section is currently under development
					and the displayed rates do not reflect the final prices. At
					this time, this feature is only for viewing my offers. A
					more complete experience will be finalized later. Thank you
					for your patience and understanding.
				</div>

				{/* CARD TEMPLATE */}
				<MotionGrid
					variants={cardsContainerVariants}
					initial="hidden"
					animate="show"
					item
					container
					mt={4}
					className="app__pricing_container"
				>
					{cards.map((card) => {
						return (
							<MotionGrid
								key={card.title}
								variants={cardItemVariants}
								item
								className="app__pricing_plan [ app__pricing_card ]"
							>
								<article className="app__pricing_plan">
									<CardContent className="app__pricing_inner">
										<Typography className="app__pricing_title">
											{card.title}
										</Typography>
										<Typography className="app__pricing_price">
											{card.price}
											<small>{card.period}</small>
										</Typography>
										<Typography className="app__pricing_info">
											{card.info}
										</Typography>
										{/* TAG */}
										{card.tags.map((tag, index) => (
											<span
												className="app__pricing_tag"
												key={index}
											>
												{tag}
											</span>
										))}
										{/* Features */}
										{card.features.map((feature, index) => (
											<ul
												className="app__pricing_features"
												key={index}
											>
												<li>
													<span
														className={`features_icon ${feature.icon}`}
													>
														{getIcon(feature.icon)}
													</span>
													{/* HTML rendering in React: dangerouslySetInnerHTML
													to display HTML text from chart array.
													This method can present security risks,
													including the injection of malicious scripts (XSS),
													if you are not sure where the HTML data is coming from. */}
													<span
													// dangerouslySetInnerHTML={{
													// 	__html: feature.text,
													// }}
													>
														{feature.text}
													</span>
												</li>
											</ul>
										))}
										{/* DETAILS */}
										<dl className="app__pricing_details">
											{card.details.map(
												(details, index) => (
													<div key={index}>
														<dt>{details.label}</dt>
														<dd>{details.value}</dd>
													</div>
												)
											)}
										</dl>
										{/* BUTTON LEARN MORE */}
										<button className="app__pricing_learn">
											<span>Learn more</span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												height="24px"
												viewBox="0 0 24 24"
												width="24px"
												fill="none"
											>
												<path
													d="M0 0h24v24H0V0z"
													fill="none"
												/>
												<path
													d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"
													fill="currentColor"
												/>
											</svg>
										</button>
										{/* BUTTON CHOOSE PLAN */}
										<button className="app__pricing_choose">
											Choose plan
										</button>
									</CardContent>
								</article>
							</MotionGrid>
						);
					})}
				</MotionGrid>
			</Grid>
		</>
	);
};

export default AppWrap(
	MotionWrap(Pricing, "app__pricing"),
	"pricing",
	"app__whitebg"
);

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./Header.scss";
import { images } from "../../constants";
import { AppWrap } from "../../wrapper";
import LayoutCards from "./LayoutCards/LayoutCards";
// import { SocialMedia } from "../../components";

const scaleVariants = {
	whileInView: {
		scale: [0, 1],
		opacity: [0, 1],
		transition: {
			duration: 1,
			ease: "easeInOut",
		},
	},
};

const animationVariants = {
	initial: { rotate: -10 },
	animate: { rotate: 10 },
};

const customCards = [
	{
		id: 1,
		title: (
			<div className="app__header-badge">
				<div className="badge-cmp app__flex">
					<motion.img
						src={images.hand}
						style={{ width: 50, height: 50 }}
						variants={animationVariants}
						initial="initial"
						animate="animate"
						transition={{
							duration: 0.5,
							ease: "easeInOut",
							repeat: Infinity,
							repeatType: "reverse",
						}}
					></motion.img>
					<div style={{ marginLeft: 20 }}>
						<p className="p-text">Hello, I am</p>
						<h1 className="head-text">Max</h1>
					</div>
				</div>

				<div className="tag-cmp app__flex">
					<p className="p-text">
						<b>Career : </b>Developer Web, Software, Mobile & Game
					</p>
					<p className="p-text">
						<b>Experience : </b>+8 Years / +100 Projects
					</p>
					<p className="p-text">
						<b>Nationality : </b>Vaud, Switzerland
					</p>

					<p className="p-text">
						<b>Status : </b>Individual Freelance
					</p>
					<p className="p-text">
						<b>Company : </b>Max Remy Dev
					</p>
				</div>
			</div>
		),
		text: (
			<>
				Since 2015, my passion for video games has led me to create
				customized scenarios. I've used my talent to enrich iconic games
				such as Bohemia Interactive's ArmA or DayZ. Learning the SQF
				Syntax and Enforce Script has enabled me to imagine and create
				captivating and unique universes, just like in Grand Theft Auto
				5 with LUA provided by Rockstar.
				<br />
				<br />
				My development career truly began in 2016-2017, with an initial
				specialization in C#. My creativity is not limited to coding. I
				also design small video games from scratch using Unity, guided
				solely by my imagination. For the design of 3D models needed for
				these projects, I use Blender. This creative process is a great
				source of satisfaction and fulfillment for me.
				<br />
				<br />
				In 2020, I took a new step by becoming a FREELANCE FULLSTACK
				developer with a specialization in BACKEND. Over the years, I
				have been able to build solid expertise in handling various
				programming languages, such as JavaScript, Node.js and Python.
				This experience has laid the solid foundations that allow me to
				be the accomplished professional I am today.
				<br />
				<br />
				It was also during this period that I strengthened my FRONTEND
				development skills. I master advanced technologies like CSS,
				React.js, Flutter, and Swift, which allows me to design complete
				and customized solutions for my clients.
				<br />
				<br />
				Whether in video game design, BACKEND, or FRONTEND development,
				my goal always remains the same: to create innovative solutions
				that meet my clients needs and add significant value to their
				projects. I hope to have the opportunity to show you how I can
				make a difference for you.
				<br />
			</>
		),
	},
];

const Header = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [key, setKey] = useState(Math.random());

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setKey(Math.random());
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const constraintsRef = useRef(null);

	return (
		<div id="home" className="app__header app__flex" ref={constraintsRef}>
			<motion.div
				whileInView={{ x: [-100, 0], opacity: [0, 1] }}
				transition={{ duration: 0.5 }}
				className="app__header-info"
			>
				<LayoutCards cards={customCards} />
				{/* <SocialMedia /> */}
			</motion.div>

			<motion.div
				whileInView={{ opacity: [0, 1] }}
				transition={{ duration: 0.5, delayChildren: 0.5 }}
				className="app__header-img"
			>
				<motion.img
					whileInView={{ scale: [0, 1] }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					src={images.profile}
					alt="profile_bg"
					className="overlay_profile"
				/>
				<motion.img
					whileInView={{ scale: [0, 1] }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					src={images.circle}
					alt="profile_circle"
					className="overlay_circle"
				/>
			</motion.div>

			<motion.div
				variant={scaleVariants}
				whileInView={scaleVariants.whileInView}
				className={`app__header-circle ${
					windowWidth <= 1200 ? "small-screen" : ""
				}`}
			>
				{[images.csharp, images.python, images.react].map(
					(circle, index) => (
						<motion.div
							className="circle-cmp app__flex"
							key={`${key}-circle-${index}`}
							drag
							dragConstraints={constraintsRef}
							dragTransition={{
								bounceStiffness: 100,
								bounceDamping: 10,
							}}
							dragElastic={1}
							whileHover={{ scale: 1.1 }}
							whileTap={{ cursor: "grabbing", scale: 0.9 }}
						>
							<img
								src={circle}
								alt="circle_bg"
								style={{ pointerEvents: "none" }}
							/>
						</motion.div>
					)
				)}
			</motion.div>
		</div>
	);
};

export default AppWrap(Header, "home", "app__whitebg");

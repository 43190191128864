import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import "./LayoutCards.scss";

const LayoutCards = ({ cards }) => {
	const [selectedId, setSelectedId] = useState(null);
	const [isScrolling, setIsScrolling] = useState(false);
	const [clickTimeout, setClickTimeout] = useState(null);
	const containerRefs = useRef([]);
	const cardsArray = cards || [1];

	const handleClick = (cardId, event) => {
		if (!clickTimeout) {
			setClickTimeout(
				setTimeout(() => {
					setClickTimeout(null);
				}, 200)
			);

			if (!isScrolling) {
				if (selectedId === cardId) {
					setSelectedId(null);
				} else {
					setSelectedId(cardId);
				}
			} else {
				setIsScrolling(false);
			}
			event.stopPropagation();
		}
	};

	return (
		<div className="layout-cards">
			{cardsArray.map((card, i) => (
				<motion.div
					id={`card-${card.id}`}
					className={selectedId === card.id ? "opened-card" : "card"}
					key={i}
					layout
					onClick={(event) => handleClick(card.id, event)}
					ref={(el) => (containerRefs.current[card.id] = el)}
					whileHover={{ scale: 1.1 }}
					whileTap={{ scale: 0.9 }}
				>
					{selectedId !== card.id && (
						<div className="card-preview app__flex">
							{card.title}
						</div>
					)}
					{selectedId === card.id && (
						<div className="opened-card-content">
							<div className="card-content p-text">
								{card.text}
							</div>
							{card.image && (
								<div>
									<img src={card.image} alt={card.title} />
								</div>
							)}
						</div>
					)}
				</motion.div>
			))}
		</div>
	);
};

export default LayoutCards;
